import { getAreaInfoById, getPartnerPromotions } from '@/store/slices/stagesSlice/stagesSlice'
import { Container, PaymentCard, SubscriptionCard, EventCard, Icon } from '@/components';
import styles from './AreaPage.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/store'
import cn from 'classnames';
import { ReactNode, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import getConfig from 'next/config';
import axios from 'axios';
import Slider, { Settings } from 'react-slick';
import { sliderSettings } from '@/components/pages/AreaPage/AreaPage.constants';
import classNames from 'classnames';
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles';
import { AreasSlug } from '@/constants';
import PaymentInAppCard from '@/components/Card/PaymentInAppCard';
import PaymentInKPlazaAppCard from '@/components/Card/PaymentInKPlazaAppCard';
import { sortPartnerPromotions } from '@/components/pages/AreaPage/AreaPage.helpers'

const { publicRuntimeConfig } = getConfig()

interface MobileResponse {
  android_current_version: string
  android_link: string
  android_required_version: string
  ios_current_version: string
  ios_link: string
  ios_required_version: string
}

export default function AreaPage({ id }: { id: string }) {
  useAreaStyles(id)
  const reduxState = useSelector<RootState, RootState>(state => state);
  const { data } = getAreaInfoById(reduxState, id);
  const { data: partnerPromotions } = getPartnerPromotions(reduxState)
  const sortedPartnerPromotions = partnerPromotions ? sortPartnerPromotions(partnerPromotions) : null
  const playMarketRef = useRef<HTMLAnchorElement>(null)
  const iosRef = useRef<HTMLAnchorElement>(null)
  const isKashirskayaPlaza = id === AreasSlug.KashirskayaPlaza
  const isKapitoliyBelyaevo = id === AreasSlug.KapitoliyBelyaevo
  const isParkovkaZil = id === AreasSlug.ParkovkaZil

  const memoSliderSettings = useMemo((): Settings => {
    return {...sliderSettings,
      prevArrow: partnerPromotions && partnerPromotions?.length < 2 ? <></> : (
        <button className={styles.partnerPromotion__sliderButton}>
          <Icon name='arrow' />
        </button>
      ),
      customPaging(): ReactNode {
        return <button className={styles.sliderDot} />;
      },
      nextArrow: partnerPromotions && partnerPromotions?.length < 2 ? <></> : (
        <button className={styles.partnerPromotion__sliderButton}>
          <Icon name='arrow' />
        </button>
      ),
      className: classNames(styles.slider, {
        [styles.isOne]: partnerPromotions?.length === 1
      }),
      slidesToShow: partnerPromotions &&  partnerPromotions?.length < 2 ? 1 : sliderSettings.slidesToShow,
      dots: partnerPromotions && partnerPromotions?.length < 2 ? false : sliderSettings.dots,
    }
  }, [partnerPromotions])

  useLayoutEffect(() => {
    const sheet = data?.customising.style_sheet;
    if (sheet) {
      document.body.style.setProperty('--font-main', `'${sheet}', sans-serif`);
      document.body.style.setProperty('--font-secondary', `'${sheet}', sans-serif`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const {data} = await axios.get<{ data: MobileResponse }>(`${publicRuntimeConfig.browserApiUrl}/versions/mobile`);
        if(playMarketRef?.current) playMarketRef.current.href = data.data.android_link
        if(iosRef?.current) iosRef.current.href = data.data.ios_link
      }
       catch (_err){}
    })()
  }, [])

  const handlePartnerPromotionClick = async (partnerPromotion: string) => {
    await axios.post(
      `${publicRuntimeConfig.browserApiUrl}/partner-promotions/${partnerPromotion}/metrics`,
    )
  }

  const isShowPartnerPromotions =
    partnerPromotions &&
    partnerPromotions?.length > 0 &&
    !isKashirskayaPlaza &&
    !isKapitoliyBelyaevo &&
    !isParkovkaZil

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div>
          <section className={styles.preview__wrapper}>
            <div className={styles.preview}>
              {data?.preview && (
                <img src={data.preview} alt={data?.name} className={styles.preview__image} />
              )}
            </div>
            {data?.customising.logo_circle ? (
              <div className={cn(styles.preview__logoCircle)}>
                <img
                  className={styles.preview__customisingLogo}
                  src={data?.customising.logo_circle}
                  alt="logo"
                />
              </div>
            ) : (
              <img className={styles.preview__logo} src={'/images/preview-logo.svg'} alt="logo" />
            )}
          </section>

          <h1 className={styles.title}>{data?.name}</h1>

          {!isKashirskayaPlaza && (
            <section className={styles.mobileApps}>
              <a ref={playMarketRef} target="_blank" rel="noreferrer noopener">
                <img
                  src="https://app.hippoparking.ru/public_files/area/1691425/87ee9841-f628-46f8-8f19-ae83ea8c86be.png"
                  alt=""
                  width="180"
                  height="54"
                />
              </a>
              <a ref={iosRef} target="_blank" rel="noreferrer noopener">
                <img
                  src="https://app.hippoparking.ru/public_files/area/1691424/Frame-39273.png"
                  alt=""
                  width="180"
                  height="53"
                />
              </a>
            </section>
          )}

          <div className={styles.cards}>
            {isShowPartnerPromotions && (
              <Slider {...memoSliderSettings}>
                {sortedPartnerPromotions?.map(partnerPromotion => (
                  <article
                    key={partnerPromotion.id}
                    className={classNames(styles.partnerPromotion, {
                      [styles.isOne]: partnerPromotions?.length === 1,
                    })}
                  >
                    <div
                      className={styles.partnerPromotion__content}
                      style={{
                        backgroundImage: `url(${partnerPromotion.banner_url})`,
                      }}
                    >
                      <p className={styles.partnerPromotion__content_name}>
                        {partnerPromotion.name}
                      </p>
                      <a
                        href={partnerPromotion.url}
                        className={styles.partnerPromotion__content_link}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => handlePartnerPromotionClick(partnerPromotion.slug)}
                      >
                        Подробнее <Icon name="arrow" />
                      </a>
                    </div>
                  </article>
                ))}
              </Slider>
            )}

            {data?.show_rates && <PaymentCard areaId={id} />}
            {isKashirskayaPlaza && (
              <>
                <PaymentInKPlazaAppCard />
                <PaymentInAppCard iosRef={iosRef} playMarketRef={playMarketRef} />
              </>
            )}
            {data?.show_subscriptions && <SubscriptionCard areaId={id} />}
            {data?.show_events && <EventCard areaId={id} />}
          </div>
        </div>
        {data?.footer_content && (
          <Container>
            <footer className={styles.footer}>
              <div dangerouslySetInnerHTML={{ __html: data.footer_content }} />
            </footer>
          </Container>
        )}
      </section>
    </div>
  )
}
