import { Settings } from 'react-slick';

export const sliderSettings: Settings = {
  dots: true,
  arrows: true,
  infinite: false,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
};