import { PartnerPromotionsResponseData } from '@/store/slices/stagesSlice/interfaces'

export const orderArray = ['halva', 'osago', 'car_money']

export const sortPartnerPromotions = (
  partnerPromotions: PartnerPromotionsResponseData[],
): PartnerPromotionsResponseData[] => {
  return [...partnerPromotions].sort((a, b) => {
    let aValueIndex = orderArray.indexOf(a.slug)
    let bValueIndex = orderArray.indexOf(b.slug)
    if (aValueIndex === -1) {
      aValueIndex = orderArray.length
    }
    if (bValueIndex === -1) {
      bValueIndex = orderArray.length
    }
    return aValueIndex - bValueIndex
  })
}
