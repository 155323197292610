import { IconComponentType, IconWrapperProps } from './interfaces'
import ChevronLeft from './ChevronLeftIcon'
import Phone from './PhoneIcon'
import Spinner from './SpinnerIcon'
import CheckLine from './CheckLineIcon'
import CreditCard from './CreditCard'
import EmptyCreditCard from './EmptyCreditCard'
import PersonCard from './PersonCard'
import CreditCards from './CreditCards'
import AttentionIcon from './AttentionIcon'
import Flag from './Flag'
import Arrow from './Arrow'
import KashirskayaPlazaCard from './KashirskayaPlazaCard'
import HPCard from './HPCard'
import AppStoreIcon from './AppStoreIcon'
import GooglePlayIcon from './GooglePlayIcon'
import FillPhoneIcon from './FillPhoneIcon'
import EditIcon from './EditIcon'
import ModalSuccessIcon from './ModalSuccessIcon'
import ModalWarningIcon from './ModalWarningIcon'
import CopyIcon from './CopyIcon'
import CheckIcon from './CheckIcon'
import FailedIcon from './FailedIcon'

const Icon = ({ name, ...props }: IconWrapperProps) => {
  let Component: IconComponentType
  Component = Spinner

  switch (name) {
    case 'arrow': {
      Component = Arrow
      break
    }
    case 'flag': {
      Component = Flag
      break
    }
    case 'check-line': {
      Component = CheckLine
      break
    }
    case 'chevron-left': {
      Component = ChevronLeft
      break
    }
    case 'phone': {
      Component = Phone
      break
    }
    case 'spinner': {
      Component = Spinner
      break
    }
    case 'credit-card': {
      Component = CreditCard
      break
    }
    case 'empty-credit-card': {
      Component = EmptyCreditCard
      break
    }
    case 'credit-cards': {
      Component = CreditCards
      break
    }
    case 'person-card': {
      Component = PersonCard
      break
    }
    case 'attention-icon': {
      Component = AttentionIcon
      break
    }
    case 'kashirskaya-plaza-card': {
      Component = KashirskayaPlazaCard
      break
    }
    case 'hp-card': {
      Component = HPCard
      break
    }
    case 'app-store-icon': {
      Component = AppStoreIcon
      break
    }
    case 'google-play-icon': {
      Component = GooglePlayIcon
      break
    }
    case 'fill-phone-icon': {
      Component = FillPhoneIcon
      break
    }
    case 'edit-icon': {
      Component = EditIcon
      break
    }
    case 'modal-success-icon': {
      Component = ModalSuccessIcon
      break
    }
    case 'modal-warning-icon': {
      Component = ModalWarningIcon
      break
    }
    case 'copy-icon': {
      Component = CopyIcon
      break
    }
    case 'check-icon': {
      Component = CheckIcon
      break
    }
    case 'failed-icon': {
      Component = FailedIcon
      break
    }
  }

  return <Component {...props} />
}

export default Icon
