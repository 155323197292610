import { Rate } from '@/components/pages/PaymentPage/interfaces'

export type SliceState = {
  areaId: string | null
  selectedId: number | null
  userEmail: string | null
  cached: {
    [key: string]: { data: CarsResponseData | null; error: PostError<FindCarFields> | null }
  }
  loading: boolean
  error: Error | null
  discountInfo: {
    loading: boolean
    errors: PostError<{ email: string; number: string }> | null
    data: any
  }
  discountAreaInfo: {
    loading: boolean
    errors: Error | null
    data: DiscountResponseData | null
  }
  areaInfo: {
    cached: {
      [key: string]: {
        data: AreaInfoResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  areaSubscriptions: {
    cached: {
      [key: string]: {
        data: AreaSubscriptionsResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  areaEvents: {
    cached: {
      [key: string]: {
        data: AreaEventsResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  paymentInfo: {
    cached: {
      [key: string]: {
        data: PaymentInfoResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  subscriptionInfo: {
    cached: {
      [key: string]: {
        data: SubscriptionInfoResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  eventInfo: {
    cached: {
      [key: string]: {
        data: EventInfoResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  payment: {
    loading: boolean
    data: {
      url: string | null
      token: string | null
      status: PaymentStatus
      paidUntil: string | null
      paidUntilEvent: any
      area: {
        id: string
        phone: string
        customising: ICustomising
      } | null
      type: PaymentReasonType | null
      platform: PaymentPlatformType | null
      stageId: number | null
    }
  }
  openExitGate: {
    loading: boolean
    error: PostError<{ qr: any }> | null
  }
  user: UserType | null
  purchasedSubscriptionInfo: {
    data: PurchasedSubscriptionInfoResponseData | null
    loading: boolean
    error: Error | null
  }
  partnerPromotions: {
    data: PartnerPromotionsResponseData[] | null
    loading: boolean
    error: Error | null
  }
  savePurchasedSubscription: {
    loading: boolean
    error: Error | null
  }
  finesInfo: {
    cached: {
      [key: string]: {
        data: FinesInfoResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
  applyReceiptDiscount: {
    loading: boolean
    error: PostError<{ stage: any }> | null
  }
  paymentReceipt: {
    cached: {
      [key: string]: {
        data: PaymentReceiptResponseData | null
        error: Error | null
      }
    }
    loading: boolean
    error: Error | null
  }
}

export interface Error {
  statusCode: number
}

export type UserType = {
  full_name?: string
  phone?: string
  gender?: string
  birthday?: string
  organization?: string
  car_brand?: string
  car_color?: string
  has_young_children?: string
  source?: string
  passport_numbe?: string
  passport_date?: string
  passport_who_gave?: string
  address?: string
  permissions?: {
    data_processing: boolean
    parking_rules: boolean
  }
}

export type ErrorFieldKey<T> = keyof (T & { catched: 'string' })

export type PostError<T> = {
  [key in ErrorFieldKey<T>]?: string[]
}

export type FindCarFields = {
  number: string
}

export interface CarsResponseDataItem {
  id: number
  number: string
  photo: {
    largePreview: string
    smallPreview: string
  }
  photo_number: {
    largePreview: string
    smallPreview: string
  }
  similarity: number
}

export interface StageResponseData {
  id: number
  number: string
  range: number
  area: Area
  invoicing: []
  similarity: number
}

export interface StageByStrictSearchResponseData {
  id: number
  number: string
  range: number
  area: Area
  invoicing: []
  similarity: number
  amount: number
}

export type ResponseSearchStageByStrictSearchSuccess = {
  result: 'success'
  data: StageByStrictSearchResponseData | null
}

export type CarsResponseData = CarsResponseDataItem[]

export type ResponseSuccess = {
  result: 'success'
  data: CarsResponseData
}

export interface ResponseError extends Error {
  result: 'error'
  data: {
    errors: PostError<FindCarFields>
  }
}

export function isResponseSuccess(
  response: ResponseSuccess | ResponseError | ResponseSearchStageByStrictSearchSuccess,
): response is ResponseSuccess {
  return response.result !== 'error'
}

export function isResponseStrictSearchStageSuccess(
  response: ResponseSearchStageByStrictSearchSuccess | ResponseError,
): response is ResponseSearchStageByStrictSearchSuccess {
  return response.result !== 'error'
}

export interface AreaInfoResponseData extends Area {
  footer_content: string
}

export interface DiscountResponseData {
  area: Area | null
  renter: {
    add_new_number: boolean
    contact_name: string | null
    contact_phone: string | null
    email: string
    id: number
    name: string
    activity_type: { name: string; value: string }
  } | null
  status: string
}

export type DiscountSuccessResponse = {
  result: string
  data: {
    not_paid_amount: number
    fines_amount: number
    paid_until: string
    discount_minutes: number
    stage_id: number
  }
}

export type DiscountErrorResponse = {
  message: string
  errors: PostError<{ email: string; number: string; token: string }>
  result: string
  statusCode: number
}

export type AreaInfoSuccess = {
  result: 'success'
  data: AreaInfoResponseData
}

export interface AreaInfoError extends Error {
  result: 'error' | 'fail'
}

export function isAreaInfoSuccess(
  response: AreaInfoSuccess | AreaInfoError,
): response is AreaInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export enum AreaSubscriptionTypeWork {
  PaidDatePlusInterval = 'paid_date_plus_interval',
  FixedPeriod = 'fixed_period',
}

export interface IAreaSubscription {
  id: number
  name: string
  interval_type: string | null
  interval: number | null
  amount: string
  description: string | null
  balance: number
  additional_fields: Array<{ name: string; is_required: boolean }>
  available_count: number
  type_work: AreaSubscriptionTypeWork
  fixed_period_from: string | null
  fixed_period_to: string | null
}

export type AreaSubscriptionsResponseData = IAreaSubscription[]

export type AreaSubscriptionsSuccess = {
  result: 'success'
  data: AreaSubscriptionsResponseData
}

export interface AreaSubscriptionsError extends Error {
  result: 'error' | 'fail'
}

export function isAreaSubscriptionsSuccess(
  response: AreaSubscriptionsSuccess | AreaSubscriptionsError,
): response is AreaSubscriptionsSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export interface ICustomising {
  logo_circle: string | null
  logo_square: string | null
  button_back_color: string | null
  cards_color: string | null
  style_sheet: null
}

export interface Area {
  id: number
  address?: string
  inn: string
  name: string
  phone: string
  preview?: string
  legal_name: string
  show_subscriptions: boolean
  ticket_type: 'only_car' | 'car_and_ticket'
  link_processing_personal_data: string | null
  link_parking_rules: string | null
  customising: ICustomising
  show_events: boolean
  show_rates: boolean
  renter: {
    id: number
    name: string
    email: string
    contact_name: null | string
    contact_phone: null | string
    activity_type: {
      value: string
      name: string
    }
    add_new_number: boolean
  }
  is_renter_discount_available: boolean
}

export type PaymentInfoResponseData = {
  id: number
  number: string
  amount: string
  range: number
  invoicing: Rate[]
  area: Area
  paid_until: string
}

export type PaymentInfoSuccess = {
  result: 'success'
  data: PaymentInfoResponseData
}

export interface PaymentInfoError extends Error {
  result: 'error' | 'fail'
}

export function isPaymentInfoSuccess(
  response: PaymentInfoSuccess | PaymentInfoError,
): response is PaymentInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type SubscriptionInfoResponseData = {
  subscription: IAreaSubscription
  area: Area
}

export type SubscriptionInfoSuccess = {
  result: 'success'
  data: SubscriptionInfoResponseData
}

export interface SubscriptionInfoError extends Error {
  result: 'error' | 'fail'
}

export function isSubscriptionInfoSuccess(
  response: SubscriptionInfoSuccess | SubscriptionInfoError,
): response is SubscriptionInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export interface IClientSubscription {
  name: string
  description: string | null
  start_at: string | null
  end_at: string | null
  daily_start: string | null
  daily_end: string | null
  number: string
}

export type AdditionalFieldNames =
  | 'full_name'
  | 'birthday'
  | 'gender'
  | 'phone'
  | 'organization'
  | 'car_brand'
  | 'car_color'
  | 'address'
  | 'has_young_children'
  | 'source'
  | 'passport_date'
  | 'passport_who_gave'
  | 'passport_number'

export interface IAdditionalField {
  name: AdditionalFieldNames
  value: string | null
  is_required: boolean | null
  type: 'string' | 'select' | 'boolean' | 'date'
}

export type PurchasedSubscriptionInfoResponseData = {
  client_subscription: IClientSubscription
  additional_fields: IAdditionalField[]
  area: Area
}

export type PurchasedSubscriptionInfoSuccess = {
  result: 'success'
  data: PurchasedSubscriptionInfoResponseData
}

export interface PurchasedSubscriptionInfoError extends Error {
  result: 'error' | 'fail'
}

export function isPurchasedSubscriptionInfoSuccess(
  response: PurchasedSubscriptionInfoSuccess | PurchasedSubscriptionInfoError,
): response is PurchasedSubscriptionInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type PartnerPromotionsResponseData = {
  id: number
  name: string
  url: string
  slug: string
  banner_url: string
  description: string
  is_unlimited: boolean
}

export type PartnerPromotionsSuccess = {
  result: 'success'
  data: PartnerPromotionsResponseData[]
}

export interface PartnerPromotionsError extends Error {
  result: 'error' | 'fail'
}

export function isPartnerPromotionsSuccess(
  response: PartnerPromotionsSuccess | PartnerPromotionsError,
): response is PartnerPromotionsSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type SavePurchasedSubscriptionSuccess = {
  result: 'success'
}

export interface SavePurchasedSubscriptionError extends Error {
  result: 'error' | 'fail'
}

export function isSavePurchasedSubscriptionSuccess(
  response: SavePurchasedSubscriptionSuccess | SavePurchasedSubscriptionError,
): response is SavePurchasedSubscriptionSuccess {
  return response.result === 'success'
}

interface Fine {
  id: number
  amount: number
  comment: string
}

export type FinesInfoResponseData = {
  fines: Fine[] | []
  totalAmount: number
}

export type FinesInfoSuccess = {
  result: 'success'
  data: FinesInfoResponseData
}

export interface FinesInfoError extends Error {
  result: 'error' | 'fail'
}

export function isFinesInfoSuccess(
  response: FinesInfoSuccess | FinesInfoError,
): response is FinesInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type PaymentReceiptField = {
  title: string
  value: string | number
}

export type PaymentReceiptResponseData = {
  receipt_link: string | null
  ofd_url: string | null
  fields: PaymentReceiptField[]
}

export type PaymentReceiptSuccess = {
  result: 'success'
  data: PaymentReceiptResponseData
}

export interface PaymentReceiptError extends Error {
  result: 'error' | 'fail'
}

export function isPaymentReceiptSuccess(
  response: PaymentReceiptSuccess | PaymentReceiptError,
): response is PaymentReceiptSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type EventInfoResponseData = any //IAreaEvent

export type EventInfoSuccess = {
  result: 'success'
  data: SubscriptionInfoResponseData
}

export interface EventInfoError extends Error {
  result: 'error' | 'fail'
}

export function isEventInfoSuccess(
  response: EventInfoSuccess | EventInfoError,
): response is EventInfoSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type StartPaymentResponseData = {
  url: string
  token: string
}

export type StartPaymentSuccess = {
  result: 'success'
  data: StartPaymentResponseData
  url: string
}

export interface StartPaymentError extends Error {
  result: 'error' | 'fail'
}

export function isStartPaymentSuccess(
  response: StartPaymentSuccess | StartPaymentError,
): response is StartPaymentSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type ApplyReceiptDiscountResponseData = {}

export type ApplyReceiptDiscountSuccess = {
  result: 'success'
  data: ApplyReceiptDiscountResponseData
}

export interface ApplyReceiptDiscountError extends Error {
  result: 'error' | 'fail'
  errors: PostError<{ stage: any }>
}

export function isApplyReceiptDiscountSuccess(
  response: ApplyReceiptDiscountSuccess | ApplyReceiptDiscountError,
): response is ApplyReceiptDiscountSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}

export type PaymentStatus = 'success' | 'error' | 'pending'
export type PaymentReasonType = 'Stage' | 'ClientSubscription' | 'ClientEvent'
export type PaymentPlatformType = 'web' | 'app' | 'saas' | 'manual' | 'web_kiosk'

export type PaymentCheckResponseData = {
  platform: PaymentPlatformType
  area: {
    id: string
    phone: string
    customising: ICustomising
  }
  status: PaymentStatus
  reason: {
    id: number | null
    paid_until: string
    type: PaymentReasonType
    end_at?: string | null
    start_at?: string | null
  }
}

export type PaymentCheckSuccess = {
  result?: 'success'
  data: PaymentCheckResponseData
}

export type PaymentCheckError = {
  result: 'fail' | 'error'
}

export function isPaymentCheckResultSuccess(
  response: PaymentCheckSuccess | PaymentCheckError,
): response is PaymentCheckSuccess {
  const result = response.result || 'success'
  return result === 'success'
}

export type OpenExitGateSuccess = {
  result: 'success'
}

export interface OpenExitGateError extends Error {
  result: 'fail' | 'error'
  errors: PostError<{ qr: any }>
}

export function isOpenExitGateResultSuccess(
  response: OpenExitGateSuccess | OpenExitGateError,
): response is OpenExitGateSuccess {
  const result = response.result || 'success'
  return result === 'success'
}

export function _isResponseSuccess<S extends { result: string }, E extends { result: string }>(
  response: S | E,
): response is S {
  return response.result !== 'error' && response.result !== 'fail'
}

export interface IAreaEvent {
  id: number
  name: string
  start_at: string
  end_at: string
  amount: string
  zones: Array<{ name: string }>
}

export type AreaEventsResponseData = IAreaEvent[]

export type AreaEventsSuccess = {
  result: 'success'
  data: AreaEventsResponseData
}

export interface AreaEventsError extends Error {
  result: 'error' | 'fail'
}

export function isAreaEventsSuccess(
  response: AreaEventsSuccess | AreaEventsError,
): response is AreaEventsSuccess {
  return response.result !== 'error' && response.result !== 'fail'
}
