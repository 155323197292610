import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { initializeStore, RootState } from '@/store'
import {
  fetchPartnerPromotions,
  getPartnerPromotions,
} from '@/store/slices/stagesSlice/stagesSlice'
import styles from './HalvaBanner.module.scss'
import { PartnerPromotionsResponseData } from '@/store/slices/stagesSlice/interfaces'
import { Icon, Title } from '@/components'
import axios from 'axios'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default function HalvaBanner() {
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: partnerPromotions } = getPartnerPromotions(reduxState)

  const promoHalva: PartnerPromotionsResponseData | null =
    partnerPromotions?.find(partnerPromotion => partnerPromotion.slug.toLowerCase() === 'halva') ??
    null

  const handleClick = async (partnerPromotion: string) => {
    await axios.post(
      `${publicRuntimeConfig.browserApiUrl}/partner-promotions/${partnerPromotion}/metrics`,
    )
  }

  useEffect(() => {
    dispatch(fetchPartnerPromotions())
  }, [])

  if (!promoHalva) return null

  return (
    <section
      className={styles.banner}
      style={{
        backgroundImage: `url(${promoHalva.banner_url})`,
      }}
    >
      <Title level={2} className={styles.banner__title}>
        {promoHalva.name}
      </Title>
      <a
        href={promoHalva.url}
        className={styles.banner__link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => handleClick(promoHalva.slug)}
      >
        Подробнее <Icon name="arrow" />
      </a>
    </section>
  )
}
